import * as React from "react"
import Layout from "../components/layout";
import BackLink from "../components/BackLink";

const NotFoundPage = () => {
  return (
    <Layout title="This page does not exist">
      <section className="max-w-screen-md mx-auto mb-12">
        <BackLink className={"mb-6"}/>
        <h1>This page could not be found...</h1>
      </section>
    </Layout>
  )
}

export default NotFoundPage
